import React, { useEffect, useState } from "react";
import { Button, Img, Text } from "components";
import { useTranslation } from "react-i18next";
import { CheckboxCustom } from "components/CheckboxCustom";
import { AddToCart } from "popups/AddToCart";
import { RepeatOrder } from "popups/RepeatOrder";
import { AddToMyOccasion } from "popups/AddToMyOccasion";
import globalRequest from "../../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import { setSnackbar } from "../../../redux/reducers/snackbar";
import * as CUSTOM from "../../../utils/helper/custom";
import * as APIS from "../../../utils/helper/Enum";
import { getAuth } from "../../../redux/reducers/loginData";
import { AddToMyOccasionAddNew } from "popups/AddToMyOccasionAddNew";
import { changeTrigger } from "redux/reducers/trigger";
import {
  updateOrderData,
  getOrderData,
} from "../../../redux/reducers/orderData";
import styled from "styled-components";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(
  React.forwardRef(function HtmlTooltip(props, ref) {
    return (
      <Tooltip {...props} ref={ref} classes={{ popper: props.className }} />
    );
  })
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    margin: 0,
    padding: 0,
    ".MuiTooltip-arrow": {
      color: "#FFFFFF", // Arrow color
      width: "28px", // Arrow width
      height: "16px", // Arrow height
      bottom: "-8px", // Position where arrow is pointing
      left: "-9px !important", // Position where arrow is pointing
    },
  },
}));
const CartProductDetail = (props) => {
  const { orderSummary } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const orderData = useSelector(getOrderData);
  const [AddToCartOpen, setAddToCartOpen] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeError, setCouponCodeError] = useState("");
  const [customizeOpen, setCustomiseOpen] = useState(false);
  const [checkedCartItemId, setCheckedCartItemId] = useState([]);
  const [RepeatOrderOpen, setRepeatOrderOpen] = useState(false);
  const [AddToMyOccasionOpen, setAddToMyOccasionOpen] = useState(false);
  const [AddToMyOccasionAddNewOpen, setAddToMyOccasionAddNewOpen] =
    useState(false);
  const [incrementQty, setIncrementQty] = useState({
    id: 0,
    qty: 0,
    product_id: 0,
    uomData: {},
  });

  /**
   * handle repeat order
   */
  const handelRepeatOrder = () => {
    setRepeatOrderOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };

  /**
   * handle add to my occasion
   */
  const handelAddToMyOccasion = () => {
    setAddToMyOccasionOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };

  /**
   * handle add to mu occasion new
   */
  const handelAddToMyOccasionAddNew = () => {
    setAddToMyOccasionAddNewOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };

  /**
   * set selected cart id
   */
  const storeChekboxCartId = (newValue, isChecked) => {
    let updatedArray = [...checkedCartItemId];
    if (isChecked) {
      updatedArray.push(newValue);
    } else {
      updatedArray = updatedArray.filter((item) => item !== newValue);
    }
    setCheckedCartItemId(updatedArray);
  };

  /**
   * Apply coupon code
   */
  const applyCouponCode = async () => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        APIS?.COUPON?.APPLY,
        "get",
        {},
        {
          params: {
            coupon_code: couponCode ? couponCode.trim() : "",
            delivery_mode:
              orderData?.DeliveryModeActiveTab == 2 ? "pickup" : "delivery",
            delivery_fees: CUSTOM.setPrice(orderSummary?.delivery_fees),
          },
        },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(
          updateOrderData({
            ...orderData,
            couponCode: couponCode.trim(),
            couponCodeId: response?.data?.data?.id,
          })
        );
        setCouponCode("");
        dispatch(changeLoader(false));
        dispatch(changeTrigger("loadSummay"));
        // setTimeout(()=>{
        //   dispatch(changeTrigger("updateinlocal"));
        // },[500])
        return;
      } else {
        setCouponCodeError(response?.message);
      }
    } catch (e) { }
    dispatch(changeLoader(false));
  };

  /**
* get modifiers
*/
  const getModifiers = async (id) => {
    try {
      let response = await globalRequest(
        `${APIS?.PRODUCTS?.GET_MODIFIER_BY_UOM_ID}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        return {
          status: "success",
          data: response?.data
        }
      } else {
        return {
          status: "success",
          data: [],
        }
      }
    } catch (e) {
      return {
        status: "error",
        message: e?.message,
      }
    }
  };

  /**
  * get product uoms
  */
  const getProductUoms = async (id) => {
    try {
      let response = await globalRequest(
        `${APIS?.PRODUCTS?.GET_UOMS_BY_PRODUCT_ID}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        return {
          status: "success",
          data: response?.data
        };
      } else {
        return {
          status: "success",
          data: [],
        };
      }
    } catch (e) {
      return {
        status: "error",
        message: e?.message,
      }
    }
  };

  /**
*  check product data
* */
  const checkProductData = async (id, qty, main_product_id) => {
    let uomData = await getProductUoms(main_product_id);
    if (uomData?.status == "error") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: uomData?.message,
          snackbarState: "error",
        })
      );
      return "error";
    }
    if (uomData?.data?.length <= 1) {
      let modifierData = await getModifiers(uomData?.data?.[0]?.id);
      if (modifierData?.status == "error") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: modifierData?.message,
            snackbarState: "error",
          })
        );
        return "error";
      }
      if (modifierData?.data?.length == 0) {
        decrementCount(id, qty, "add");
        return "incremented";
      }
    }
    return "open_popup";
  }

  /**
   *  add more quantity in existing item
   */
  const incrementCount = async (id, qty, productId, uomData) => {
    const data = await checkProductData(id, qty, productId);
    if (data != "open_popup") return
      handelRepeatOrder();
    setIncrementQty({
      id: id,
      qty: qty,
      product_id: productId,
      uomData: uomData,
    });
  };

  /**
   * remove coupon code
   */
  const removeCouponCode = () => {
    dispatch(changeLoader(true));
    setTimeout(() => {
      dispatch(
        updateOrderData({ ...orderData, couponCode: "", couponCodeId: "" })
      );
      setCouponCode("");
      dispatch(changeLoader(false));
      dispatch(changeTrigger("loadSummay"));
    }, [500]);
  };

  /**
   * remove or update cart item quantity (Subtract Quantity)
   */
  const decrementCount = async (id, qtyCount, type = "subtract") => {
    dispatch(changeLoader(true));
    try {
      let inputData = {
        cart_item_id: id,
        qty: type == "subtract" ? Number(qtyCount) - 1 : Number(qtyCount) + 1,
      };
      let response = await globalRequest(
        APIS?.CART?.UPDATE_QTY,
        "put",
        inputData,
        {},
        true
      );
      response = response?.data;
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: response?.message,
          snackbarState: response.status == "SUCCESS" ? "success" : "error",
        })
      );
      if (response.status == "SUCCESS") {
        dispatch(changeTrigger("fetchCartCount"));
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const isShowCouponCode = () => {
    if (orderData?.DeliveryModeActiveTab == 1) {
      if (orderData?.pageStep == 4) {
        return true;
      }
    } else if (orderData?.DeliveryModeActiveTab == 2) {
      if (orderData?.pageStep == 4) {
        return true;
      }
    } else if (orderData?.DeliveryModeActiveTab == 3) {
      return false;
    }
    return false;
  };

  /**
   * reload add my occassion data
   */
  useEffect(() => {
    if (AddToMyOccasionAddNewOpen == "refetch") {
      setAddToMyOccasionAddNewOpen(false);
      handelAddToMyOccasion();
    }
  }, [AddToMyOccasionAddNewOpen]);

  /**
   * repeat card quantity
   */
  useEffect(() => {
    switch (RepeatOrderOpen) {
      case "incQty":
        setRepeatOrderOpen(false);
        decrementCount(incrementQty?.id, incrementQty?.qty, "add");
        break;
      case "addNewProduct":
        setRepeatOrderOpen(false);
        setAddToCartOpen(true);
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RepeatOrderOpen]);

  /**
   * checked cart quantity by checkbox
   */
  useEffect(() => {
    const allIds = props?.cartData?.map((item) => item?.id) || [];
    setCheckedCartItemId(allIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * if coupon applied
   */
  useEffect(() => {
    setCouponCode(orderData?.couponCode ? orderData?.couponCode : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderData?.couponCode) {
      if (!isShowCouponCode()) {
        removeCouponCode();
      }
    }
  }, [orderData]);

  return (
    <>
      <div className="max-w-[350px] w-full sm:pl-0 xs:pl-0 sm:max-w-full sm:order-1">
        <div className="bg-white-A700 w-full">
          <div className="px-2 py-1 flex flex-row items-center justify-between border-b border-gray-300 w-full">
            <div className="w-auto flex flex-row items-center gap-0.5">
              <Img
                className="h-10 object-contain w-10 min-w-[1.5rem]"
                src="/images/state-layer-unselect.svg"
                alt="rabbitholelogo"
              />
              <Text
                className="text-black-900 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mb-1"
                //  onClick={handelAddToMyOccasionAddNew}
              >
                {checkedCartItemId.length}/{props?.cartData.length}
              </Text>
            </div>
            <div className="w-auto pr-3">
              {auth?.id != 0 && checkedCartItemId.length != 0 ? (
                <Text
                  className="cursor-pointer text-pink-800 whitespace-nowrap font-medium font-nunitomedium text-sm leading-6 mb-1"
                  onClick={handelAddToMyOccasion}
                >
                  {t("moveToOccasion")}
                </Text>
              ) : null}
            </div>
          </div>
          <div className="flex flex-col items-start gap-4 py-4 w-full">
            {props?.cartData.map((items, index) => {
              const totalPrice =
                Number(items?.productUom?.sellingPrice) +
                (items?.cartModifiers || []).reduce((acc, modifierItem) => {
                  return (
                    acc +
                    (modifierItem?.cartModifierItems || []).reduce(
                      (innerAcc, priceItem) => {
                        return (
                          innerAcc +
                          Number(priceItem?.productUomModifierItem?.price || 0)
                        );
                      },
                      0
                    )
                  );
                }, 0);
              return (
                <>
                  {!CUSTOM.findInactiveOrDelete(items, "item") ? (
                    <div
                      key={`keyid-${index}-${Math.random()}`}
                      className="px-5 flex flex-row items-center justify-between w-full"
                    // key={`cart-item-key-${index}`}
                    >
                      <div className="w-full flex flex-row items-center gap-[0] flex-1">
                        <CheckboxCustom
                          onChange={(e) => {
                            storeChekboxCartId(items?.id, e.target.checked);
                          }}
                          checked={checkedCartItemId.includes(items?.id)}
                        />
                        <div className="flex flex-col gap-0.5 -ml-4 rtl:ml-0 rtl:-mr-4">
                          <Text className="text-black-900 whitespace-nowrap font-medium font-nunitomedium text-sm leading-5 text-ellipsis overflow-hidden max-w-[125px] ">
                            {CUSTOM.getdataByLangKey(
                              items?.product?.productLocales,
                              "",
                              "name",
                              "10"
                            )}
                          </Text>
                          {
                            items?.isCustomized ?
                              <Text
                                className="cursor-pointer cursor-pointer text-gray-700 flex items-center whitespace-nowrap text-xs font-mohrroundedaltregular font-normal leading-4"
                                onClick={() => {
                                  setIncrementQty({
                                    ...incrementQty,
                                    product_id: items?.product?.id,
                                    uomData: items,
                                  });
                                  setCustomiseOpen(true);
                                }}
                              >
                                {t("customize")}
                                <Img
                                  className="h-4 object-contain w-4 min-w-[1rem] mt-0.5"
                                  src="/images/arrow_forward.svg"
                                  alt="rabbitholelogo"
                                />
                              </Text> : null
                          }
                        </div>
                      </div>
                      <div className="w-auto flex flex-row justify-between gap-5">
                        <div className="bg-white-A700 border border-gray-300 border-solid flex flex-row items-center justify-start py-[3px] px-1 rounded w-full min-w-[68px] max-w-[68px]">
                          <Img
                            className="h-3 w-3 cursor-pointer"
                            src="/images/img_menu.svg"
                            alt="menu_One"
                            onClick={(e) => {
                              decrementCount(
                                items?.id,
                                items?.quantity,
                                "subtract"
                              );
                            }}
                          />
                          <Text className="flex-1 text-black-900 text-center text-xs leading-4">
                            {items?.quantity}
                          </Text>
                          <Img
                            className="h-3 w-3 cursor-pointer"
                            src="/images/img_plus_black_900.svg"
                            alt="plus_One"
                            onClick={(e) => {
                              incrementCount(
                                items?.id,
                                items?.quantity,
                                items?.productId,
                                items
                              );
                            }}
                          />
                        </div>
                        <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitomedium text-xs leading-loose">
                          {t("sar")}{" "}
                          {CUSTOM.setPrice(totalPrice * items?.quantity)}
                        </Text>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={`keyid-${index}-${Math.random()}`}
                      className="bg-cover bg-no-repeat min-h-[60px] p-1 px-5 flex flex-row items-center justify-between w-full"
                      style={{
                        backgroundImage: "url('images/img_group16.svg')",
                      }}
                    >
                      <div className="w-full flex flex-row items-center gap-[0] flex-1">
                        <CheckboxCustom
                          className="opacity-down"
                          checked={true}
                        />
                        <div className="flex flex-col gap-0.5 -ml-4 rtl:ml-0 rtl:-mr-4">
                          <Text className="text-black-900 whitespace-nowrap font-medium font-nunitomedium text-sm leading-5 text-ellipsis overflow-hidden max-w-[125px]">
                            {CUSTOM.getdataByLangKey(
                              items?.product?.productLocales,
                              "",
                              "name",
                              "10"
                            )}
                          </Text>
                        </div>
                      </div>
                      <div className="w-auto flex flex-row justify-between deactive-product-found">
                        <Button className="bg-red-100 cursor-pointer font-nunitoregular h-max inset-y-[0] min-w-[89px] my-auto py-1 right-[26%] rtl:right-0 rounded text-center text-gray-900_01 text-xs">
                          {t("unavailable")}
                        </Button>
                        <Img
                          className="h-6 cursor-pointer w-6"
                          src="/images/img_trash.svg"
                          alt="trash"
                          onClick={(e) => {
                            decrementCount(items?.id, 1, "subtract");
                          }}
                        />
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
          <div className="py-1 px-5">
            <div className="p-2 bg-gray-50_02 flex items-center gap-2">
              <Img
                className="h-6 object-contain w-6"
                src="/images/img_thumbsup.svg"
                alt="thumbsup"
              />
              <textarea
                type="text"
                placeholder={t("anyNote?WriteHere")}
                value={orderData?.notes}
                onChange={(e) => {
                  dispatch(
                    updateOrderData({ ...orderData, notes: e.target.value })
                  );
                }}
                className="border-[0] p-0 bg-transparent flex-1 h-[24px] text-[12px]"
              />
            </div>
          </div>
          {auth?.id ? (
            <div className="pt-4 px-5 pb-5 flex flex-col gap-5">
              {!orderData?.couponCode && isShowCouponCode() ? (
                <div className="bg-white-A700 border border-gray-300 border-solid flex flex-row items-center justify-between">
                  <div className="flex flex-row px-2 items-center">
                    <Img
                      className="h-6 w-6"
                      src="/images/img_iconamoondiscountlight.svg"
                      alt="iconamoondiscou"
                    />
                    <input
                      className="ml-2 rtl:ml-0 rtl:mr-2 placeholder:text-gray-700 text-gray-900 text-xs font-nunitoregular pr-4 w-[80%]"
                      size="txtnunitoRegular12Gray700"
                      placeholder={t("couponCode")}
                      value={couponCode}
                      onChange={(e) => {
                        setCouponCodeError("");
                        setCouponCode(
                          CUSTOM.strFormat(e.target.value, "max:30")
                        );
                      }}
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center rounded w-auto">
                    <div className="flex flex-col items-center justify-center px-3 py-[9px] rounded w-[135px]">
                      <Text
                        className="text-center text-pink-800 text-sm xs:text-xs w-auto cursor-pointer"
                        size="txtnunitoMedium14"
                        onClick={(e) => {
                          setCouponCodeError("");
                          if (couponCode) {
                            applyCouponCode();
                          }
                        }}
                      >
                        {t("applyCoupon")}
                      </Text>
                    </div>
                  </div>
                </div>
              ) : null}

              {couponCodeError ? (
                <span className="red-error">{couponCodeError}</span>
              ) : (
                ""
              )}

              {orderData?.couponCode ? (
                <div className="bg-white-A700 border border-gray-300 border-solid flex flex-row items-center justify-between">
                  <div className="flex flex-col px-2 py-[9px] gap-0.5 items-start">
                    <Text className="text-black-900 whitespace-nowrap font-medium font-nunitoregular text-sm leading-5">
                      {orderData?.couponCode}
                    </Text>
                    <Text className="text-gray-700 flex items-center whitespace-nowrap text-xs font-nunitoregular font-normal leading-4">
                      {t("offerAppliedOnTheBill")}
                    </Text>
                  </div>
                  <div className="flex flex-col items-center justify-center rounded w-auto">
                    <div className="flex flex-col items-center justify-center px-3 py-[9px] rounded w-auto">
                      <Text
                        className="text-center text-pink-800 text-sm xs:text-xs w-auto cursor-pointer"
                        size="txtnunitoMedium14"
                        onClick={(e) => {
                          removeCouponCode();
                        }}
                      >
                        {t("Remove")}
                      </Text>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}

          <div className="px-5 mt-3 flex flex-col gap-3">
            <div className="flex flex-row items-center justify-between">
              <Text className="text-black-900 whitespace-nowrap font-medium font-nunitoregular text-sm leading-5">
                {t("orderSummary")}
              </Text>
            </div>
            <div className="flex flex-row items-center justify-between">
              <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-5 text-start">
                {t("productsubtotal")}
              </Text>
              <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                {t("sar")} {CUSTOM.setPrice(orderSummary?.sub_total)}
              </Text>
            </div>

            {orderSummary?.delivery_fees ? (
              <div className="flex flex-row items-center justify-between">
                <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-start">
                  {t("deliveryFee")}
                </Text>
                <div className="flex flex-row items-center justify-end">
                  <HtmlTooltip
                    placement="top-start"
                    arrow
                    title={
                      <>
                        <div className="bg-white-A700 py-3 px-5 rounded-lg shadow-lg -ml-5">
                          <Text className="leading-5 text-black text-sm font-medium">
                            {t("dropzonediscount")}
                          </Text>
                        </div>
                      </>
                    }
                  >
                    <div>
                      <Img
                        src="/images/img_info-black.svg"
                        className="w-4 h-4 mr-1.5 cursor-pointer"
                        alt="info"
                      />
                    </div>
                  </HtmlTooltip>
                  <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                    <span
                      className={
                        orderSummary?.pickup_discount ? "line-through" : ""
                      }
                    >
                      {t("sar")} {CUSTOM.setPrice(orderSummary?.delivery_fees)}
                    </span>
                    {orderSummary?.pickup_discount ? (
                      <span className="text-teal-400 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                        {" "}
                        {t("sar")}{" "}
                        {CUSTOM.setPrice(
                          orderSummary?.delivery_fees -
                          orderSummary?.pickup_discount
                        )}{" "}
                      </span>
                    ) : null}
                    {orderSummary?.pickup_discount
                      ? `(-${orderSummary?.pickupDiscountPercent}%)`
                      : ""}
                  </Text>
                </div>
              </div>
            ) : null}

            {orderSummary?.vat ? (
              <div className="flex flex-row items-center justify-between">
                <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-start">
                  {t("taxVAT")}
                </Text>
                <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                  {t("sar")} {CUSTOM.setPrice(orderSummary?.vat)}
                </Text>
              </div>
            ) : null}

            {orderSummary?.coupon_discount ? (
              <div className="flex flex-row items-center justify-between">
                <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-start">
                  {t("couponDiscount")}
                </Text>
                <Text className="text-teal-400 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                  - {t("sar")} {CUSTOM.setPrice(orderSummary?.coupon_discount)}
                </Text>
              </div>
            ) : null}

            {/* {orderSummary?.pickup_discount ? (
                <div className="flex flex-row items-center justify-between">
                  <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-start">
                    {t("dropzonediscount")}
                  </Text>
                  <Text className="text-teal-400 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                    - {t("sar")} {CUSTOM.setPrice(orderSummary?.pickup_discount)}
                  </Text>
                </div>
              ) : null} */}
            <div className="border-b mt-2 border-black-900"></div>
          </div>
          <div className="bg-white-A700 flex flex-row items-start justify-between py-4 px-5 shadow-bs1 w-full">
            <Text className="text-base text-black-900 font-nunitoregular">
              {t("totalAmount")}
            </Text>
            <Text className="text-base text-black-900 text-right font-nunitomedium">
              {t("sar")} {CUSTOM.setPrice(orderSummary?.total_amount)}
            </Text>
          </div>
        </div>
      </div>

      {AddToCartOpen === true ? (
        <AddToCart
          closepopup={setAddToCartOpen}
          productId={incrementQty?.product_id}
        />
      ) : null}

      {customizeOpen === true ? (
        <AddToCart
          closepopup={setCustomiseOpen}
          productId={incrementQty?.product_id}
          customizeItem={incrementQty}
        />
      ) : null}

      {/* {AddToCartSelectedOpen === true ? (
        <AddToCartSelected closepopup={setAddToCartSelectedOpen} />
      ) : null} */}

      {RepeatOrderOpen === true ? (
        <RepeatOrder
          closepopup={setRepeatOrderOpen}
          cartIds={incrementQty}
          cartData={props?.cartData}
        />
      ) : null}

      {AddToMyOccasionOpen === true ? (
        <AddToMyOccasion
          closepopup={setAddToMyOccasionOpen}
          setNewOccasion={handelAddToMyOccasionAddNew}
          moveToOccasion={checkedCartItemId}
        />
      ) : null}
      {AddToMyOccasionAddNewOpen === true ? (
        <AddToMyOccasionAddNew
          closepopup={setAddToMyOccasionAddNewOpen}
          modalTopIcon="/images/add_to-occasion-icon.svg"
          OccasionTitle={t("addNewOccasion")}
          OccasionParagraph={t("enterBelowDetailsToAddANewOccasionDot")}
        />
      ) : null}
    </>
  );
};

export default CartProductDetail;
