import React, { useEffect, useState } from "react";
import { Img, Text } from "components";
import { useTranslation } from "react-i18next";
import * as CUSTOM from "../../../utils/helper/custom";
import { useDispatch, useSelector } from "react-redux";
import globalRequest from "../../../utils/global/globalRequest";
import { changeLoader } from "../../../redux/reducers/loader";
import * as APIS from "../../../utils/helper/Enum";
import { changeTrigger } from "../../../redux/reducers/trigger";
import {
  updateOrderData,
  getOrderData,
} from "../../../redux/reducers/orderData";
import styled from "styled-components";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const HtmlTooltip = styled(
  React.forwardRef(function HtmlTooltip(props, ref) {
    return (
      <Tooltip {...props} ref={ref} classes={{ popper: props.className }} />
    );
  })
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    margin: 0,
    padding: 0,
    ".MuiTooltip-arrow": {
      color: "#FFFFFF", // Arrow color
      width: "28px", // Arrow width
      height: "16px", // Arrow height
      bottom: "-8px", // Position where arrow is pointing
      left: "-9px !important", // Position where arrow is pointing
    },
  },
}));

const CartProductDetail = ({ orderDetails, orderSummary }) => {
  const { t } = useTranslation();
  const orderData = useSelector(getOrderData);
  const orderDetailsData = orderDetails?.orderData;
  const dispatch = useDispatch();
  const [prepTime, setPrepTime] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponCodeError, setCouponCodeError] = useState("");

  /**
   * get group item name from order data
   */
  const getGroupItemName = (items) => {
    return items
      .map((item) =>
        CUSTOM.getdataByLangKey(item?.modifierGroupItemLocales, "", "name")
      )
      .join(", ");
  };

  /**
   * Applu coupon code
   */
  const applyCouponCode = async () => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        APIS?.CUSTOMCAKE?.APPLY_COUPON_CODE,
        "get",
        {},
        {
          params: {
            coupon_code: couponCode ? couponCode.trim() : "",
            order_id: orderDetailsData?.id,
            delivery_mode:
              orderData?.deliveryModeTabIndex == 2 ? "pickup" : "delivery",
            delivery_fees: CUSTOM.setPrice(orderSummary?.delivery_fees),
          },
        },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(
          updateOrderData({
            ...orderData,
            couponCode: couponCode.trim(),
            couponCodeId: response?.data?.data?.id,
          })
        );
        setCouponCode("");
        dispatch(changeTrigger("loadSummay"));
        return;
      } else {
        setCouponCodeError(response?.message);
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  /**
   * remove coupon code
   */
  const removeCouponCode = () => {
    dispatch(changeLoader(true));
    dispatch(
      updateOrderData({ ...orderData, couponCode: "", couponCodeId: "" })
    );
    setCouponCode("");
    setTimeout(() => {
      dispatch(changeTrigger("loadSummay"));
      dispatch(changeLoader(false));
    }, [500]);
  };


  const isShowCouponCode = () => {
    if (orderData?.deliveryModeTabIndex == 1) {
      if (orderData?.pageStep == 4) {
        return true;
      }
    } else if (orderData?.deliveryModeTabIndex == 2) {
      if (orderData?.pageStep == 4) {
        return true;
      }
    } else if (orderData?.deliveryModeTabIndex == 3) {
      return false;
    }
    return false;
  };

  /**
   * get prep time from order details page
   */
  useEffect(() => {
    let totalTime = 0;
    orderDetailsData?.orderDetails?.forEach((oitem) => {
      oitem?.orderItemDetails?.cakeDetail?.forEach((orderIt) => {
        orderIt?.modifierGroupItems?.forEach((nitem) => {
          totalTime += nitem?.prepTime;
        });
      });
    });
    setPrepTime(CUSTOM.minutsToHour(totalTime));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  /**
   * apply coupon code when page reload
   */
  useEffect(() => {
    setCouponCode(orderData?.couponCode ? orderData?.couponCode : "");
  }, [orderData]);

  useEffect(() => {
    if (orderData?.couponCode) {
      if (!isShowCouponCode()) {
        removeCouponCode();
      }
    }
  }, [orderData]);

  return (
    <>
      <div className="max-w-[350px] w-full sm:pl-0 xs:pl-0 sm:max-w-full sm:order-1">
        <div className="bg-white-A700 w-full">
          <div className="py-1 px-5 py-4">
            <div className="mt-[10px] w-full ">
              <div className="bg-red-50_01 p-4 rounded w-full">
                <div className="flex flex-row items-start justify-between w-full">
                  <div className="flex items-center">
                    <Img
                      className="h-6 object-contain w-6"
                      src="/images/img_clock_black_900.svg"
                      alt="clock"
                    />
                    <Text className="text-sm text-black-900 w-auto mx-2 font-nunitomedium font-normal">
                      {t("estimatedPriceColon")}
                    </Text>
                  </div>
                  <Text className="text-sm text-gray-700 w-auto font-nunitoregular font-normal text-[16px] text-gray-700 sm:text-base">
                    SAR{" "}
                    {CUSTOM.setPrice(
                      orderDetailsData?.priceDetails?.total_amount
                    )}
                  </Text>
                </div>
                <div className="flex flex-row items-start justify-between w-full mt-3">
                  <div className="flex items-center">
                    <Img
                      className="h-6 object-contain w-6"
                      src="/images/img_television.svg"
                      alt="television"
                    />
                    <Text className="text-sm text-black-900 w-auto mx-2 font-nunitomedium font-normal">
                      {t("estimatedPrepDotTimeColon")}
                    </Text>
                  </div>
                  <Text className="text-sm text-gray-700 w-auto font-nunitoregular font-normal text-[16px] text-gray-700 sm:text-base">
                    {prepTime} hrs
                  </Text>
                </div>
              </div>
              <div className="flex flex-col w-full mt-6 detail-box-height">
                {orderDetailsData?.orderDetails.map((oitem, index) => (
                  <div
                    className="flex flex-col items-start justify-between w-full mb-6"
                    key={`outer-${index}`}
                  >
                    {oitem?.orderItemDetails?.cakeDetail?.map(
                      (orderIt, oindex) => (
                        <div className="mb-6" key={`inner-${oindex}`}>
                          {/* Repeated elements extracted outside the inner mapping */}
                          <Text className="font-nunitomedium text-base mb-1">
                            {CUSTOM.getdataByLangKey(
                              orderIt?.modifierGroupLocales,
                              "",
                              "name"
                            )}
                          </Text>
                          <Text className="font-nunitoregular text-sm text-black-900 leading-[24px]">
                            {getGroupItemName(orderIt?.modifierGroupItems)}
                          </Text>
                        </div>
                      )
                    )}

                    <div className="mb-6" key={`inner-23122413243`}>
                      {/* Repeated elements extracted outside the inner mapping */}
                      <Text className="font-nunitomedium text-base mb-1">
                        {t("referenceImageSlashLink")}
                      </Text>
                      <div className="font-nunitoregular text-sm text-black-900 leading-[24px]">
                        <div className="flex flex-wrap flex-row gap-3 items-start justify-start w-full">
                          {oitem?.orderItemDetails?.imageLinks?.map(
                            (itemsLinks, ind) => {
                              return (
                                <div
                                  className="key-value-map"
                                  key={`imgskey${ind}`}
                                >
                                  <Img
                                    className="cursor-pointer h-[64px] w-[64px] object-cover rounded-lg"
                                    src={itemsLinks}
                                    alt="rectangle19120"
                                    loading="lazy"
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                        {oitem?.orderItemDetails?.referenceLink ? (
                          <Text
                            className="font-nunitoregular text-sm text-black-900 leading-[24px] underline mt-5"
                            style={{ textWrap: "pretty" }}
                          >
                            <a
                              href={oitem?.orderItemDetails?.referenceLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {oitem?.orderItemDetails?.referenceLink}
                            </a>
                          </Text>
                        ) : null}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-2 bg-gray-50_02 flex items-center gap-1 mt-5">
                <Img
                  className="h-6 object-contain w-6"
                  src="/images/img_thumbsup.svg"
                  alt="thumbsup"
                />
                <textarea
                  type="text"
                  placeholder={t("anyNote?WriteHere")}
                  className="border-[0] p-0 bg-transparent flex-1"
                  value={orderData?.notes}
                  onChange={(e) => {
                    dispatch(
                      updateOrderData({ ...orderData, notes: e.target.value })
                    );
                  }}
                />
              </div>

              <div className="pt-4 px-5 pb-5 flex flex-col gap-5">
                {!orderData?.couponCode && isShowCouponCode() ? (
                  <div className="bg-white-A700 border border-gray-300 border-solid flex flex-row items-center justify-between">
                    <div className="flex flex-row px-2 items-center">
                      <Img
                        className="h-6 w-6"
                        src="/images/img_iconamoondiscountlight.svg"
                        alt="iconamoondiscou"
                      />
                      <input
                        className="ml-2 rtl:ml-0 rtl:mr-2 placeholder:text-gray-700 text-gray-900 text-xs font-nunitoregular pr-4 w-[80%]"
                        size="txtnunitoRegular12Gray700"
                        placeholder={t("couponCode")}
                        value={couponCode}
                        onChange={(e) => {
                          setCouponCodeError("");
                          setCouponCode(
                            CUSTOM.strFormat(e.target.value, "max:30")
                          );
                        }}
                      />
                    </div>
                    <div className="flex flex-col items-center justify-center rounded w-auto">
                      <div className="flex flex-col items-center justify-center px-3 py-[9px] rounded w-[135px]">
                        <Text
                          className="text-center text-pink-800 text-sm xs:text-xs w-auto cursor-pointer"
                          size="txtnunitoMedium14"
                          onClick={(e) => {
                            setCouponCodeError("");
                            if (couponCode) {
                              applyCouponCode();
                            }
                          }}
                        >
                          {t("applyCoupon")}
                        </Text>
                      </div>
                    </div>
                  </div>
                ) : null}
                {couponCodeError ? (
                  <span className="red-error">{couponCodeError}</span>
                ) : (
                  ""
                )}

                {orderData?.couponCode ? (
                  <div className="bg-white-A700 border border-gray-300 border-solid flex flex-row items-center justify-between">
                    <div className="flex flex-col px-2 py-[9px] gap-0.5 items-start">
                      <Text className="text-black-900 whitespace-nowrap font-medium font-nunitoregular text-sm leading-5">
                        {orderData?.couponCode}
                      </Text>
                      <Text className="text-gray-700 flex items-center whitespace-nowrap text-xs font-nunitoregular font-normal leading-4">
                        {t("offerAppliedOnTheBill")}
                      </Text>
                    </div>
                    <div className="flex flex-col items-center justify-center rounded w-auto">
                      <div className="flex flex-col items-center justify-center px-3 py-[9px] rounded w-auto">
                        <Text
                          className="text-center text-pink-800 text-sm xs:text-xs w-auto cursor-pointer"
                          size="txtnunitoMedium14"
                          onClick={(e) => {
                            removeCouponCode();
                          }}
                        >
                          {t("Remove")}
                        </Text>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="px-5 flex flex-col gap-3">
                <div className="flex flex-row items-center justify-between">
                  <Text className="text-black-900 whitespace-nowrap font-medium font-nunitoregular text-sm leading-5">
                    {t("orderSummary")}
                  </Text>
                </div>
                <div className="flex flex-row items-center justify-between">
                  <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-5 text-start">
                    {t("productsubtotal")}
                  </Text>
                  <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                    {t("sar")} {CUSTOM.setPrice(orderSummary?.sub_total)}
                  </Text>
                </div>
                {orderSummary?.delivery_fees ? (
                  <div className="flex flex-row items-center justify-between">
                    <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-start">
                      {t("deliveryFee")}
                    </Text>
                    <div className="flex flex-row items-center justify-end">
                      <HtmlTooltip
                        placement="top-start"
                        arrow
                        title={
                          <>
                            <div className="bg-white-A700 py-3 px-5 rounded-lg shadow-lg -ml-5">
                              <Text className="leading-5 text-black text-sm font-medium">
                                {t("dropzonediscount")}
                              </Text>
                            </div>
                          </>
                        }
                      >
                        <div>
                          <Img
                            src="/images/img_info-black.svg"
                            className="w-4 h-4 mr-1.5 cursor-pointer"
                            alt="info"
                          />
                        </div>
                      </HtmlTooltip>
                      <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                        <span
                          className={
                            orderSummary?.pickup_discount ? "line-through" : ""
                          }
                        >
                          {t("sar")}{" "}
                          {CUSTOM.setPrice(orderSummary?.delivery_fees)}
                        </span>
                        {orderSummary?.pickup_discount ? (
                          <span className="text-teal-400 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                            {" "}
                            {t("sar")}{" "}
                            {CUSTOM.setPrice(
                              orderSummary?.delivery_fees -
                                orderSummary?.pickup_discount
                            )}{" "}
                          </span>
                        ) : null}
                        {orderSummary?.pickup_discount
                          ? `(-${orderSummary?.pickupDiscountPercent}%)`
                          : ""}
                      </Text>
                    </div>
                  </div>
                ) : null}
                {orderSummary?.vat ? (
                  <div className="flex flex-row items-center justify-between">
                    <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-start">
                      {t("taxVAT")}
                    </Text>
                    <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                      {t("sar")} {CUSTOM.setPrice(orderSummary?.vat)}
                    </Text>
                  </div>
                ) : null}
                {orderSummary?.coupon_discount ? (
                  <div className="flex flex-row items-center justify-between">
                    <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-start">
                      {t("couponDiscount")}
                    </Text>
                    <Text className="text-teal-400 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                      - {t("sar")}{" "}
                      {CUSTOM.setPrice(orderSummary?.coupon_discount)}
                    </Text>
                  </div>
                ) : null}
                {/* {orderSummary?.pickup_discount ? (
                  <div className="flex flex-row items-center justify-between">
                    <Text className="text-gray-700 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-start">
                      {t("dropzonediscount")}
                    </Text>
                    <Text className="text-teal-400 whitespace-nowrap font-medium font-nunitoregular text-xs leading-4 text-end">
                      - {t("sar")}{" "}
                      {CUSTOM.setPrice(orderSummary?.pickup_discount)}
                    </Text>
                  </div>
                ) : null} */}
                <div className="border-b mt-2 border-black-900"></div>
              </div>
              <div className="bg-white-A700 flex flex-row items-start justify-between py-4 px-5 shadow-bs1 w-full">
                <Text className="text-base text-black-900 font-nunitoregular">
                  {t("totalAmount")}
                </Text>
                <Text className="text-base text-black-900 text-right font-nunitomedium">
                  {t("sar")} {CUSTOM.setPrice(orderSummary?.total_amount)}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartProductDetail;
