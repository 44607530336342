import { Img, Text } from "components";
import { DeleteModal } from "popups/DeleteModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { addDeleteGetLocalStorage, storageKeys } from "utils/global/localData";
import { getDefaultLanguage } from "../../utils/helper/custom";

const DesignMyOwnCakeQuestionOneHeader = (props) => {
  const { t , i18n} = useTranslation();
  const navigate = useNavigate();
  const [removeCardOpen, setRemoveCardOpen] = useState(false);
  const [language, setLanguage] = useState(false);

  const handlelanguage = () => {
    setLanguage(!language);
  };

  const enStyles = !language ? "hidden" : "block";

  const deleteConfirm = (isDelete) => {
    if (isDelete == "yes") {
      if (props?.allInputData?.id) {
        addDeleteGetLocalStorage(
          storageKeys.ORDER_TAB,
          "mycustomcake",
          "add",
          "single"
        );
        navigate("/my-order");
      } else {
        navigate("/design-my-cake");
      }
    } 
    setRemoveCardOpen(false);
  };

  return (
    <>
      <div className={props.className} id={props.id}>
        <div className="flex flex-row md:gap-5 items-center justify-start mx-auto max-w-[1110px] w-full md:px-4">
          {/* <div className="flex items-center text-gray-700">
            {!!props?.p0 ? (
              <Text
                className={`text-black-900 text-right text-sm ${
                  props?.isEdit ? "cursor-pointer" : ""
                }`}
                size="txtnunitoRegular14"
                onClick={(e) => {
                  if (props?.isEdit) {
                    props?.setActiveId(0);
                  }
                }}
              >
                {props?.p0}
              </Text>
            ) : null}
            {!!props?.p13 ? (
              <>
                /
                <Text
                  className="text-gray-700 text-right text-sm"
                  size="txtnunitoRegular14"
                >
                  {props?.p13}
                </Text>
              </>
            ) : null}
          </div> */}
          <Img
            className="h-[62px] sm:h-auto w-[114px] cursor-pointer"
            src="/images/logo.svg"
            alt="rabbitholelogo"
            onClick={() => navigate("/")}
          />
          <Text
              className="text-black-900 text-center text-sm cursor-pointer mx-auto"
              size="txtnunitoMedium14Black900"
            >
              {props?.title}
            </Text>
          <div
            className="px-3 text-black-900 text-sm cursor-pointer mt-[2px] relative"
            size="txtnunitoRegular14Black900"
            onClick={handlelanguage}
          >
            {getDefaultLanguage("capital")}
            <div
              className={`${enStyles} bg-pink-800 h-1 w-full absolute -bottom-[10px] left-0 z-50`}
            ></div>
            <div
              className={`${enStyles} absolute left-auto top-[30px] -right-10 z-50 before-click`}
            >
              <div className="w-auto flex flex-row bg-white-A700 shadow-md mx-auto">
                <div className="flex flex-col py-2 w-full">
                  <ul className="flex flex-col">
                    <li
                      className="flex items-center gap-2 px-4 py-2 text-black-900 hover:bg-gray-50_02 hover:text-pink-800"
                      onClick={(e) => {
                        localStorage.setItem(
                          "jsahdjkanbn",
                          "sd542s3ad2sa1d3iu748923DSF"
                        );
                        i18n.changeLanguage("en");
                        let defaultLanguage = "ltr";
                        document.documentElement.setAttribute(
                          "dir",
                          defaultLanguage
                        );
                        window.location.reload();
                      }}
                    >
                      <Img
                        className="h-6 object-contain w-6 min-w-[1.5rem]"
                        src="/images/flag-eng.svg"
                        alt="rabbitholelogo"
                      />
                      <Text className="whitespace-nowrap font-nunitoregular font-normal text-base leading-6 -mt-1 self-center">
                        English
                      </Text>
                    </li>
                    <li
                      className="flex items-center gap-2 px-4 py-2 text-black-900 hover:bg-gray-50_02 hover:text-pink-800"
                      onClick={(e) => {
                        localStorage.setItem(
                          "jsahdjkanbn",
                          "sd5s42s3ad2sa1d3iu748923DSF"
                        );
                        i18n.changeLanguage("ar");
                        let defaultLanguage = "rtl";
                        document.documentElement.setAttribute(
                          "dir",
                          defaultLanguage
                        );
                        window.location.reload();
                      }}
                    >
                      <Img
                        className="h-6 object-contain w-6 min-w-[1.5rem]"
                        src="/images/flag-arb.svg"
                        alt="rabbitholelogo"
                      />
                      <Text className="whitespace-nowrap font-nunitoregular font-normal	text-base leading-6 -mt-1 self-center">
                        عربي
                      </Text>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {!!props?.exittext ? (
            <Text
              className="ml-[0] text-black-900 text-right text-sm cursor-pointer"
              size="txtnunitoMedium14Black900"
              onClick={(e) => {
                setRemoveCardOpen(true);
              }}
            >
              {props?.exittext}
            </Text>
          ) : null}
        </div>
      </div>
      {removeCardOpen ? (
        <DeleteModal
          deleteTitle={t("cancelTheCustomize")}
          deleteParagraph={t("customizeMessage")}
          customizeAlertMessage={t("customizeAlertMessage")}
          deleteIcon={"/images/exit-close.png"}
          deleteConfirm={deleteConfirm}
          closepopup={setRemoveCardOpen}
        />
      ) : null}
    </>
  );
};

DesignMyOwnCakeQuestionOneHeader.defaultProps = {};

export default DesignMyOwnCakeQuestionOneHeader;
