import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import * as APIS from "../../../../utils/helper/Enum";
import { getAuth } from "../../../../redux/reducers/loginData";
import * as CUSTOM from "../../../../utils/helper/custom";
import { currentTrigger } from "redux/reducers/trigger";
import {
  Button,
  Img,
  DatepickerInput,
  Text,
  Radio,
  TimeSlotRadio,
} from "components";
import {
  updateOrderData,
  getOrderData,
} from "../../../../redux/reducers/orderData";
const guest_id = CUSTOM.getDeviceID();
let defaultTimeSloatDara = {
  is_show: 0,
  initialDate: "",
  day: "",
  date: "",
  user_id: 0,
  guest_id: guest_id,
};

const Tab = styled.div`
  /* Add the selected styles when the component is selected */
  ${({ isSelected }) =>
    isSelected &&
    ` 
  border-color: #BD0043 !important;
`}
`;

const Panal = styled.div`
  /* Add the selected styles when the component is selected */
  ${({ isSelected }) =>
    isSelected &&
    ` 
  display: block !important;
`}
`;

const DeliveryDateTime = (props) => {
  const { prepTime, orderDetails } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const orderData = useSelector(getOrderData);
  const loadingValue = useSelector(currentTrigger);
  const [timeslotSelected, setTimeslotSelected] = useState(null);
  const [disableTimeButton, setDisableTimeButton] = useState(true);
  const [slotData, setSlotData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [timeslotData, setTimeslotData] = useState(defaultTimeSloatDara);
  const [activeTab, setActiveTab] = useState(null);
  const [showDeliveryType, setShowDeliveryType] = useState({
    fastest: false,
    schedule: false,
    loading: true,
  });

  let pageStep = orderData?.pageStep ? orderData?.pageStep : 1;

  const changeDate = (e, type='') => {
    let date = CUSTOM.convertDateString(e, "yyyy-MM-dd");
    let day = CUSTOM.getDayOfWeek(date);
    const updateTimeslotData = { ...timeslotData };
    updateTimeslotData.date = date;
    updateTimeslotData.day = day;
    if (type == "defaultDate") {
      updateTimeslotData.initialDate = date;
    }
    setTimeslotData(updateTimeslotData);
    dispatch(
      updateOrderData({
        ...orderData,
        delivery_date: date,
      })
    );
  };

  /**
   * change delivery type when change tab
   */
  const handleTabClick = (index) => {
    if (index == 1 && cartData?.length > 3) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: t("fastestTimeError"),
          snackbarState: "error",
        })
      );
      return;
    }
    dispatch(
      updateOrderData({
        ...orderData,
        deliveryDateTimeActiveTab: index,
        delivery_type: index == 1 ? "fastest" : "schedule",
      })
    );
    // if (!timeslotData?.date && index != 1) {
    //   let newdate = CUSTOM.changeDateFormat(new Date(), "yyyy-MM-dd");
    //   changeDate(newdate);
    // }
  };

  /**
   * get time sloat details
   */
  const getTimeSlots = async () => {
    try {
      let inputData = { ...timeslotData, user_id: auth?.id };
      dispatch(changeLoader(true));
      let response = await globalRequest(
        APIS?.CHECKOUT?.GET_SLOT_LIST_BY_DATE,
        "get",
        {},
        { params: inputData }
      );
      response = response?.data;
      if (response.status != "SUCCESS") {
        setSlotData([]);
      } else {
        setSlotData(response?.data?.slots);
      }
    } catch (e) {
      console.log(e?.message);
      //setSlotData([]);
    }
    dispatch(changeLoader(false));
  };

  /**
   * get Current Time from server
   */
  const getCurrentTime = async () => {
    let newdate = CUSTOM.convertDateString(new Date(), "yyyy-MM-dd");
    try {
      let response = await globalRequest(
        APIS?.CART?.GET_CURRENT_DATE,
        "get",
        {},
        {}
      );
      response = response?.data;
      if (response.status == "SUCCESS") {
        newdate = CUSTOM.convertDateString(response?.data, "yyyy-MM-dd");
      }
    } catch (e) {
      console.log(e?.message);
    }
    changeDate(newdate, "defaultDate");
  };

  const setSlotTime = (items) => {
    setTimeslotSelected(items);
  };

  const continueTime = () => {
    let delivery_type = activeTab == 1 ? "fastest" : "schedule";
    if (delivery_type == "fastest" && orderData?.totalCartItem == 4) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: t("fastestTimeError"),
          snackbarState: "error",
        })
      );
      return;
    }
    if (delivery_type == "fastest") {
      setTimeslotSelected(null);
      dispatch(
        updateOrderData({
          ...orderData,
          timeSloat: { id: 0 },
          pageStep: 4,
          delivery_type: delivery_type,
          timeslotUserData: timeslotData,
          sloatApiData: [],
        })
      );
    } else {
      dispatch(
        updateOrderData({
          ...orderData,
          timeSloat: timeslotSelected,
          pageStep: 4,
          delivery_type: delivery_type,
          delivery_date: timeslotData?.date,
          timeslotUserData: timeslotData,
          sloatApiData: slotData,
        })
      );
    }
    // setTimeout(() => {
    //   dispatch(changeTrigger("updateinlocal"));
    // }, 1500);
  };

  /**
   * Get Setting Data for enabling and disabling delivery type buttons
   */
  const getSettingData = async () => {
    dispatch(changeLoader(true));
    let obj = {
      fastest: false,
      schedule: false,
    };
    try {
      let response = await globalRequest(
        APIS?.CART?.GET_SETTING_DATA,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        let fastestSetting = response?.data?.fastestDeliverySetting == 1;
        let scheduleSetting = response?.data?.scheduleDeliverySetting == 1;
        obj.fastest = fastestSetting;
        obj.schedule = scheduleSetting;
        if (
          (!fastestSetting && !scheduleSetting) ||
          (activeTab == 1 && !fastestSetting) ||
          (activeTab == 2 && !scheduleSetting)
        ) {
          let data = { ...orderData };
          delete data["deliveryDateTimeActiveTab"];
          delete data["delivery_type"];
          if (!scheduleSetting) {
            delete data["timeSloat"];
            delete data["timeslotUserData"];
            setTimeslotSelected(null);
            setTimeslotData({ ...timeslotData, date: "" });
          }
          dispatch(updateOrderData(data));
        }
      }
    } catch (e) {
      console.log(e?.message);
    }
    setShowDeliveryType({ ...obj, loading: false });
    dispatch(changeLoader(false));
  };

  // useEffect(() => {
  //   console.log("sdfsdsdskdnskdskd", orderData)
  //   let newdate = CUSTOM.changeDateFormat(new Date(), "yyyy-MM-dd");
  //   changeDate(newdate);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [orderData?.DeliveryModeActiveTab]);

  /**
   * call api
   */
  useEffect(() => {
    if (timeslotData?.date) {
      getTimeSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeslotData]);

  /**
   * when data change on second step time sloat data will remove
   */
  useEffect(() => {
    if (orderData?.pageStep == 3) {
      let timeslotDataObj = {};
      if (orderData?.delivery_mode == "home_office") {
        timeslotDataObj = {
          ...timeslotData,
          lat: orderData?.home_office?.itemDetail?.lattitude,
          long: orderData?.home_office?.itemDetail?.longitude,
        };
      } else {
        timeslotDataObj = {
          ...timeslotData,
          lat: orderData?.home_office?.latitude,
          long: orderData?.home_office?.longitude,
        };
      }
      setTimeslotData(timeslotDataObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.pageStep]);

  /**
   * change delivery type when change tab
   */
  useEffect(() => {
    setActiveTab(
      orderData?.deliveryDateTimeActiveTab
        ? orderData?.deliveryDateTimeActiveTab
        : 0
    );
    if (orderData?.deliveryDateTimeActiveTab == 1) {
      setTimeslotData({ ...timeslotData, date: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.deliveryDateTimeActiveTab]);

  /**
   * change delivery type when change tab
   */
  useEffect(() => {
    setTimeout(() => {
      if (timeslotSelected != null) {
        let exitOrNot = slotData?.find(
          (item) => item?.id == timeslotSelected?.id
        );
        if (!exitOrNot) {
          dispatch(
            updateOrderData({ ...orderData, timeSloat: { id: 0 }, pageStep: 3 })
          );
          setTimeslotSelected(null);
        }
      }
    }, 1200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  /**
   * check time sloat data when reload page
   */
  useEffect(() => {
    if (orderData?.timeslotUserData) {
      setTimeslotSelected(
        orderData?.timeSloat && orderData?.timeSloat?.id != 0
          ? orderData?.timeSloat
          : null
      );
      setTimeslotData(orderData?.timeslotUserData);
      setSlotData(orderData?.sloatApiData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.timeslotUserData]);

  /**
   * check disable button when time change
   */
  useEffect(() => {
    if (activeTab == 2) {
      let status = true;
      let exitOrNot = slotData?.find(
        (item) => item?.id == timeslotSelected?.id
      );
      if (exitOrNot) {
        status = false;
      }
      setDisableTimeButton(status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slotData, timeslotSelected, orderData?.delivery_type]);

  /**
   * call api
   */
  useEffect(() => {
    let a = ["loggedin", "loadCartData"];
    if (a.includes(loadingValue)) {
      getTimeSlots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingValue]);

  useEffect(() => {
    if (activeTab && activeTab == 1) {
      dispatch(updateOrderData({ ...orderData, delivery_type: "fastest" }));
    } else if (activeTab && activeTab == 2) {
      dispatch(updateOrderData({ ...orderData, delivery_type: "schedule" }));
    }
  }, []);

  useEffect(() => {
    if (orderData?.pageStep == 3 && orderData?.delivery_type == "schedule") {
      getCurrentTime();
    }
  }, [orderData?.delivery_type, orderData?.pageStep]);

  useEffect(() => {
    setCartData(CUSTOM.getCartData());
  }, [loadingValue]);

  useEffect(() => {
    if (pageStep == 3 && orderData?.delivery_mode == "home_office") {
      getSettingData();
    }
    if (pageStep < 3 || orderData?.delivery_mode != "home_office") {
      setShowDeliveryType({
        fastest: false,
        schedule: false,
        loading: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData?.delivery_mode, pageStep]);

  return (
    <>
      <div className="relative flex flex-col w-full">
        {pageStep > 3 ? (
          <div className="flex xs:flex-col xs:items-start flex-row gap-4 items-center justify-between w-full mt-0.5 mb-2">
            <div className="flex flex-col gap-3 flex-row items-start justify-start w-full">
              <div className="flex flex-col gap-1 items-start justify-start flex-1">
                <Text className="text-base text-black-900 font-nunitomedium">
                  {orderData?.delivery_mode == "home_office"
                    ? t("deliveryDateAndTime")
                    : ""}
                  {orderData?.delivery_mode == "pickup_point"
                    ? t("pickUpDateTime")
                    : ""}
                </Text>
                <div className="flex gap-1 flex-row items-center">
                  <Text className="text-black-900 text-sm font-nunitoregular">
                    {orderData?.delivery_type != "fastest" ? (
                      <>
                        {CUSTOM.changeDateFormat(
                          timeslotData?.date,
                          "dd/MM/yyyy"
                        )}{" "}
                        {CUSTOM.formatTimeRange(
                          timeslotSelected?.openTime,
                          timeslotSelected?.closeTime
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                    {orderData?.delivery_type == "fastest" ? (
                      <>{t("fastestTime")}</>
                    ) : null}
                  </Text>
                </div>
              </div>
              <Button
                className="flex items-center bg-red-50_01 justify-center  px-2 py-[7px] rounded"
                leftIcon={
                  <Img
                    className="h-[18px] mr-2 rtl:mr-0 rtl:ml-2"
                    src="images/img_clock.svg"
                    alt="clock"
                  />
                }
              >
                <div className="text-left text-gray-900_01 font-nunitomedium text-sm rtl:text-right">
                  {t("estimatedPrepTime")} {CUSTOM.minutsToHour(prepTime)} hrs
                </div>
              </Button>
            </div>
            <Button
              className="w-auto mx-auto px-3 py-1.5 text-sm xs:mx-0"
              size="sm"
              variant="OutlineBlack"
              hover={true}
              hoverclass="bg-black-900"
              onClick={(e) => {
                dispatch(
                  updateOrderData({
                    ...orderData,
                    timeSloat: null,
                    pageStep: 3,
                  })
                );
              }}
            >
              {t("change")}
            </Button>
          </div>
        ) : null}

        {pageStep < 4 ? (
          <div className="flex xs:flex-col xs:items-start flex-col gap-4 items-center justify-start w-full mt-0.5 mb-2">
            <div className="flex flex-row xs:flex-col xs:items-start md:gap-5 items-center justify-start w-full">
              <div className="flex flex-col gap-1 items-start justify-start flex-1">
                <Text className="text-base text-black-900 font-nunitomedium">
                  {t("deliveryDateAndTime")}
                </Text>
                <div className="flex gap-1 flex-row items-center">
                  <Text className="text-black-900 text-sm font-nunitoregular">
                    {t("forYourItemsChooseTheDeliveryDateAndTime")}
                  </Text>
                </div>
              </div>
              {pageStep == 3 ? (
                <Button
                  className="flex items-center bg-red-50_01 justify-center  px-2 py-[7px] rounded"
                  leftIcon={
                    <Img
                      className="h-[18px] mr-2 rtl:mr-0 rtl:ml-2"
                      src="images/img_clock.svg"
                      alt="clock"
                    />
                  }
                >
                  <div className="text-left text-gray-900_01 font-nunitomedium text-sm rtl:text-right">
                    {t("estimatedPrepTime")} {CUSTOM.minutsToHour(prepTime)} hrs
                  </div>
                </Button>
              ) : null}
            </div>
          </div>
        ) : null}

        {pageStep == 3 ? (
          <>
            <div className="gap-5 grid sm:grid-cols-1 grid-cols-2 justify-center w-full mt-5">
              {showDeliveryType?.loading &&
              orderData?.DeliveryModeActiveTab == 1 ? null : (
                <>
                  {orderData?.DeliveryModeActiveTab == 1 &&
                  showDeliveryType?.fastest ? (
                    <Tab
                      className="common-pointer bg-white-A700 border border-gray-300 border-solid flex flex-1 gap-3 flex-row items-center justify-start p-5 w-full"
                      isSelected={activeTab === 1}
                      onClick={() => handleTabClick(1)}
                      style={cartData.length > 3 ? { opacity: "0.6" } : {}}
                    >
                      <Radio
                        className="flex items-center justify-start"
                        name="Deliverytype"
                        checked={activeTab === 1}
                        onChange={() => handleTabClick(1)}
                      />
                      <div className="flex flex-row gap-1 items-start justify-start w-auto">
                        <Img
                          className="h-6 w-6"
                          src="images/img_send.svg"
                          alt="send"
                        />
                        <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                          {t("fastestTime")}
                        </Text>
                      </div>
                    </Tab>
                  ) : null}

                  {orderData?.DeliveryModeActiveTab == 1 &&
                  !showDeliveryType?.schedule ? null : (
                    <Tab
                      className="common-pointer bg-white-A700 border border-gray-300 border-solid flex flex-1 gap-3 flex-row items-center justify-start p-5 w-full"
                      isSelected={activeTab === 2}
                      onClick={() => handleTabClick(2)}
                    >
                      <Radio
                        className="flex items-center justify-start"
                        name="Deliverytype"
                        checked={activeTab === 2}
                        onChange={() => handleTabClick(2)}
                      />
                      <div className="flex flex-row gap-1 items-start justify-start w-auto">
                        <Img
                          className="h-6 w-6"
                          src="images/img_calendar_24x24.svg"
                          alt="calendar"
                        />
                        <Text className="text-black-900 text-sm w-auto font-nunitomedium">
                          {t("scheduleTime")}
                        </Text>
                      </div>
                    </Tab>
                  )}

                  {orderData?.DeliveryModeActiveTab == 1 &&
                  !showDeliveryType?.schedule &&
                  !showDeliveryType?.fastest ? (
                    <Text className="text-base text-red-900 font-nunitoregular">
                      {t("experiencingHighTraffic")}
                    </Text>
                  ) : null}
                </>
              )}
            </div>
            <Panal
              isSelected={activeTab === 2}
              className="flex flex-col items-start justify-start w-full mt-7 hidden"
            >
              <div className="flex flex-col gap-3 items-start justify-start w-full">
                <Text className="text-base text-black-900 font-nunitomedium">
                  {t("selectDate")}
                </Text>
                <div className="max-w-[350px] w-full">
                  <DatepickerInput
                    format={"DD/MM/YYYY"}
                    required={true}
                    disablePast={true}
                    label={t("deliveryDate")}
                    value={timeslotData?.date}
                    min={timeslotData?.initialDate}
                    defaultValue={timeslotData?.initialDate}
                    onChange={(e) => {
                      changeDate(e);
                      setTimeslotSelected({ ...timeslotSelected, id: 0 });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-3 items-start justify-start w-full">
                <Text className="text-base text-black-900 font-nunitomedium">
                  {slotData?.length > 0
                    ? t("availableTimeSlots")
                    : t("noSloatFound")}
                </Text>
                <div className="w-full flex flex-wrap gap-3">
                  {slotData?.map((items, index) => {
                    return (
                      <TimeSlotRadio
                        label={CUSTOM.formatTimeRange(
                          items?.openTime,
                          items?.closeTime
                        )}
                        checked={{
                          slotId: timeslotSelected?.id,
                          id: items?.id,
                        }}
                        name="time"
                        className="w-auto"
                        id={`slot-${index}`}
                        onClick={(e) => {
                          setSlotTime(items);
                        }}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="w-full max-w-[242px] mt-8 flex flex-row gap-4 mb-[18px]">
                <Button
                  className="flex-1 mx-auto"
                  size="lg"
                  variant="FillBlack"
                  hover={true}
                  hoverclass="bg-white-A700"
                  disabled={disableTimeButton}
                  onClick={(e) => {
                    continueTime();
                  }}
                >
                  {t("continue")}
                </Button>
              </div>
            </Panal>
            <Panal
              isSelected={activeTab === 1}
              className="flex flex-col items-start justify-start w-full hidden"
            >
              <div className="w-full max-w-[242px] mt-8 flex flex-row gap-4 mb-[18px]">
                <Button
                  className="flex-1 mx-auto"
                  size="lg"
                  variant="FillBlack"
                  hover={true}
                  hoverclass="bg-white-A700"
                  onClick={(e) => {
                    continueTime();
                  }}
                >
                  {t("continue")}
                </Button>
              </div>
            </Panal>
          </>
        ) : null}
      </div>
    </>
  );
};

export default DeliveryDateTime;
