import React, { useState, useRef, useEffect } from "react";
import {
  GoogleMap,
  Autocomplete,
  Marker,
  OverlayView,
} from "@react-google-maps/api";
import {
  updateMyAddress,
  getAddressData,
} from "../../redux/reducers/myAddress";
import * as CUSTOM from "../../utils/helper/custom";
import { Img } from "components";
import { CloseSVG } from "assets/images";
import { useDispatch, useSelector } from "react-redux";

let timer = null;

function MyMapComponent() {
  let addressData = useSelector(getAddressData);
  const mapRef = useRef(null); // Add a mapRef to access the GoogleMap instance
  const dispatch = useDispatch();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [searchAddress, setSearchAddress] = useState("");
  const autocompleteRef = useRef(null);
  const [markerStatus, setMarkerStatus] = useState(false);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [mapHeight, setMapHeight] = useState("70vh");
  const [markerPosition, setMarkerPosition] = useState({
    lat: addressData?.lattitude,
    lng: addressData?.longitude,
  });
  const zoom =11;

  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      setSelectedPlace(place);
    }
  };

  const handleMarkerDrag = (event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    setMarkerStatus(false);
  };

  const handleBoundsChanged = (e) => {
    if (mapRef.current && markerStatus == false) {
      const newCenter = mapRef.current.getCenter();
      if (newCenter) {
        if (newCenter.lat() != markerPosition.lat) {
          clearTimeout(timer);
          timer = setTimeout(() => {
            setMarkerPosition({
              lat: newCenter.lat(),
              lng: newCenter.lng(),
            });
          }, 300);
        }
      }
    }
  };

  const reverseGeocode = (lat, lng) => {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    fetch(geocodingUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          let place = data.results[0];
          const addressComponents = place.address_components;
          let streetNumber = "";
          let apartmentNumber = "";
          let city = "";
          let state = "";
          let country = "";
          let postalCode = "";
          for (const component of addressComponents) {
            const types = component.types;
            if (types.includes("street_number")) {
              streetNumber = component.long_name;
            }
            if (types.includes("subpremise")) {
              apartmentNumber = component.long_name;
            }
            if (types.includes("locality")) {
              city = component.long_name;
            }
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (types.includes("country")) {
              country = component.long_name;
            }
            if (types.includes("postal_code")) {
              postalCode = component.long_name;
            }
          }
          dispatch(
            updateMyAddress({
              ...addressData,
              formatted_address: place.formatted_address,
              lattitude: lat,
              address: place.formatted_address,
              longitude: lng,
              city: city,
              building_number: streetNumber,
              state: state,
              postal_code: postalCode,
              country: country,
              floor_apartment: apartmentNumber,
              street_number: streetNumber,
            })
          );
        }
      });
  };

  const setCurrentLocation = async () => {
    let latlng = await CUSTOM.currentLocation();
    setMarkerPosition({
      lat: latlng?.lat ? latlng?.lat : markerPosition?.lat,
      lng: latlng?.lng ? latlng?.lng : markerPosition?.lng,
    });
  }

  useEffect(() => {
    if (mapRef.current && selectedPlace && selectedPlace.geometry) {
      const newPosition = {
        lat: selectedPlace.geometry.location.lat(),
        lng: selectedPlace.geometry.location.lng(),
      };
      setMarkerPosition(newPosition);
      if (selectedPlace.geometry.viewport) {
        mapRef.current.fitBounds(selectedPlace.geometry.viewport);
      }
    }
  }, [selectedPlace]);

  useEffect(() => {
    if (markerPosition.lat && markerPosition.lat !== addressData?.lattitude) {
      reverseGeocode(markerPosition.lat, markerPosition.lng);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerPosition]);

  const handleResize = () => {
    if (window.innerWidth <= 990) {
      setMapHeight("calc(100vh - 410px)");  
    } else {
      setMapHeight("70vh");  
    }
  };

  useEffect(() => {
    handleResize();  
    window.addEventListener("resize", handleResize); 

    return () => {
      window.removeEventListener("resize", handleResize);  
    };
  }, []);

  const mapStyles = {
    width: "100%",
    height: mapHeight,
  };

  return (
    <GoogleMap
      mapContainerStyle={mapStyles}
      // center={markerPosition}
      center={markerPosition}
      zoom={zoom}
      onLoad={(map) => {
        mapRef.current = map;
        // if (firstLoad == false && markerPosition?.lat!=process.env.REACT_APP_DEFAULT_LAT) {
        //   const geocoder = new window.google.maps.Geocoder();
        //   geocoder.geocode({ location: markerPosition }, (results, status) => {
        //     if (status === "OK" && results[0]) {
        //       let PlaceSearch = results[0];
        //       if (PlaceSearch.geometry.viewport) {
        //         mapRef.current.fitBounds(PlaceSearch.geometry.viewport);
        //       } 
        //     }
        //   });
        //   setFirstLoad(true);
        // }
        // Save the GoogleMap instance in the ref
      }}
      onBoundsChanged={(e) => {
        handleBoundsChanged(e);
      }}
      onClick={(e) => {
        handleMarkerDrag(e);
      }}
    >
      <div className="my-location-icon" onClick={(e) => {
        setCurrentLocation();
      }}></div>
      <div className="search-map-input">
        <Img
          className="cursor-pointer h-5 ltr:mr-3 rtl:ml-3 my-auto absolute top-[14px] ltr:left-[10px] rtl:right-[10px]"
          src="/images/img_search_black_900.svg"
          alt="search"
        />
        <Autocomplete
          onLoad={(autocomplete) => {
            autocompleteRef.current = autocomplete;
          }}
          options={{
            componentRestrictions: { country: "SA" },
          }}
          onPlaceChanged={handlePlaceSelect}
        >
          <input
            type="text"
            className="form-control"
            placeholder="Enter Location"
            id="google-address-search"
            onChange={(e) => {
              setSearchAddress(e.target.value);
            }}
          />
        </Autocomplete>
        {searchAddress ? (
          <CloseSVG
            fillColor="#666666"
            className="cursor-pointer h-5 my-auto absolute ltr:right-2 rtl:left-2 top-[14px]"
            height={24}
            width={24}
            viewBox="0 0 24 24"
            onClick={(e) => {
              setSearchAddress("");
              document.getElementById("google-address-search").value = "";
            }}
          />
        ) : null}
      </div>

      {markerPosition && (
        <Marker
          position={markerPosition}
          draggable={true} // Enable marker dragging
          onDragEnd={handleMarkerDrag} // Callback function for when the marker is dragged
          onDragStart={(e) => {
            setMarkerStatus(true);
            setInfoWindowOpen(true);
          }}
          onClick={() => setInfoWindowOpen(!infoWindowOpen)}
        >
            {infoWindowOpen && (
          <OverlayView
            position={markerPosition}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={() => ({ x: -130, y: -110 })} // Adjust this to position the overlay
          >
            <div
              className="custom-info-window"
              style={{
                background: "white",
                padding: "16px 20px 16px 16px",
                borderRadius: "8px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.3)",
                width: "260px",
                position: "relative",
              }}
            >
              <button
                onClick={() => setInfoWindowOpen(false)}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              >
                ✖
              </button>
              <p className="text-sm ">{addressData?.formatted_address}</p>
              {/* Add more details or controls as needed */}
            </div>
          </OverlayView>
        )}
        </Marker>
      )}
    </GoogleMap>
  );
}

export default MyMapComponent;
